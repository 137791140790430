import { Autocomplete, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  ButterflyInput,
  ButterflyLabel,
  convertStringToNumber,
  SectionContainer,
} from "../../reusable-things";
import { saleManagerUI } from "../../sale-manager-ui";
import { filterOptionsLimit } from "../../universal-utility-functions";

export const BasicInfoInput = observer(() => {
  const { name, category, editMode, discount } = saleManagerUI;
  const [saleAmountString, setSaleAmountString] = useState<string | undefined>(
    discount ? discount + "" : undefined
  );
  const [saleAmountError, setSaleAmountError] = useState(false);

  useEffect(() => {
    processSaleAmountString();
  }, [saleAmountString]);

  const processSaleAmountString = () => {
    if (!saleAmountString) return;
    const convertedValue = convertStringToNumber(saleAmountString);
    if (!isNaN(convertedValue)) {
      saleManagerUI.discount = convertedValue;
      setSaleAmountError(false);
      return;
    }
    setSaleAmountError(true);
  };

  return (
    <StyledComponent>
      <StyledSectionContainer>
        <StyledButterflyInput
          label={"Name this sale*"}
          placeholder={`eg: Summer Sale`}
          value={name}
          onChange={(event) => (saleManagerUI.name = event.target.value)}
          disabled={!editMode}
        />
        <StyledButterflyInput
          error={saleAmountError}
          label={"Enter sale amount (%)*"}
          placeholder={`eg: 20`}
          value={saleAmountString}
          onChange={(event) => setSaleAmountString(event.target.value)}
          disabled={!editMode}
        />
      </StyledSectionContainer>
      <StyledSectionContainer>
        <ProductCustomTypeContainer>
          <ButterflyLabel>Product Custom Type</ButterflyLabel>
          <Autocomplete
            placeholder={`eg: gemstones`}
            value={category}
            filterOptions={filterOptionsLimit}
            onChange={(event, value) => (saleManagerUI.category = (value as any))}
            disabled={!editMode}
            renderInput={(params) => (
              <TextField {...params} fullWidth placeholder="e.g: Red Beads" />
            )}
            options={saleManagerUI.customTypes}
            fullWidth
          />
        </ProductCustomTypeContainer>
      </StyledSectionContainer>
    </StyledComponent>
  );
});

const StyledComponent = styled.div``;

const StyledSectionContainer = styled.div`
  ${SectionContainer};
`;

const StyledButterflyInput = styled(ButterflyInput)`
  width: 49%;
`;

const ProductCustomTypeContainer = styled.div`
  width: 49%;
  justify-content: center;
`;

const SaleStatusContent = styled.div`
  display: block;
  margin: auto;
`;
