import { css } from "styled-components";

export const SectionContainer = css`
  display: flex;
  background-color: white;
  width: 95%;
  padding: 2.5%;
  margin: auto;
  margin-bottom: 3rem;
  justify-content: space-between;
  border-radius: 0.5rem;
`;