import { Autocomplete, Chip, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { SectionContainer } from "../../reusable-things";
import { ButterflyLabel } from "../../reusable-things/src/ButterflyInputLabel";
import { saleManagerUI } from "../../sale-manager-ui";
import { filterOptionsLimit } from "../../universal-utility-functions";
import { SaleManagerPageButton } from "./SaleManagerPageButton";

export const TagsInput = observer(() => {
  const {
    editMode,
    tagsToApplyDiscountToo,
    tagGroupsToApplyDiscountToo,
    shopsTags,
  } = saleManagerUI;
  return (
    <StyledSectionContainer>
      <ButterflyLabel>
        Enter individual product tags to apply discount to{" "}
      </ButterflyLabel>
      <Autocomplete
        disabled={!editMode}
        multiple
        filterOptions={filterOptionsLimit}
        limitTags={2}
        options={shopsTags}
        value={tagsToApplyDiscountToo}
        onChange={(_, newValue) =>
          (saleManagerUI.tagsToApplyDiscountToo = (newValue as any))
        }
        renderInput={(params) => (
          <TextField {...params} placeholder="e.g: Red Beads" />
        )}
        renderTags={(value: any, getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        sx={{ width: "500px" }}
      />
      <br />
      <br />
      <ButterflyLabel>
        Enter combined product tags to apply discount to
      </ButterflyLabel>
      {tagGroupsToApplyDiscountToo.map((tagPair, index) => {
        return (
          <Autocomplete
            disabled={!editMode}
            multiple
            limitTags={2}
            options={shopsTags}
            value={tagPair.tags}
            onChange={(_, newValue) =>
              (saleManagerUI.tagGroupsToApplyDiscountToo[index].tags = newValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={`e.g: Red Beads & Blue Beads & ...`}
              />
            )}
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <>
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                  {index !== value.length - 1 && "&"}
                </>
              ))
            }
            sx={{ width: "500px" }}
          />
        );
      })}
      <SaleManagerPageButton
        onClick={() =>
          (saleManagerUI.tagGroupsToApplyDiscountToo = [
            ...saleManagerUI.tagGroupsToApplyDiscountToo,
            {tags: []},
          ])
        }
        variant={"outlined"}
        disabled={!editMode}
      >
        + add another tag combination
      </SaleManagerPageButton>
    </StyledSectionContainer>
  );
});

const StyledSectionContainer = styled.div`
  ${SectionContainer};
  display: block;
`;
