import { Autocomplete, Button, Chip, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { cleanerPageUI } from "../../cleaner-page-ui";
import { filterOptionsLimit } from "../../universal-utility-functions";

export const TagsToAddInput = observer(
  ({ selected }: { selected: string[] }) => {
    const { tagsToAddToProductsAndVariants, shopsTags, loadingAddingTags } =
      cleanerPageUI;

    return (
      <StyledComponent>
        <Autocomplete
          freeSolo
          multiple
          filterOptions={filterOptionsLimit}
          limitTags={2}
          options={shopsTags}
          value={tagsToAddToProductsAndVariants}
          onChange={(_, newValue) =>
            (cleanerPageUI.tagsToAddToProductsAndVariants = (newValue as any))
          }
          renderInput={(params) => (
            <TextField {...params} placeholder="e.g: Red Beads" />
          )}
          renderTags={(value: any, getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          sx={{ width: "500px" }}
        />
        {!loadingAddingTags ? (
          <StyledButton
            onClick={() => cleanerPageUI.addTags(selected)}
            variant="contained"
          >
            Add Tags
          </StyledButton>
        ) : <>Loading</>}
      </StyledComponent>
    );
  }
);

const StyledComponent = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  && {
    margin: auto 0;
    margin-left: 2rem;
    width: 8rem;
    height: 2rem;
  }
`;
