import React, { useMemo } from "react";
import styled from "styled-components";
import { ProductAndProductVariantOptionInput } from "./ProductAndProductVariantOptionInput";
import moment from "moment";

const optionsMonths = [
  {
    value: moment().add("month", -2).valueOf(),
    label: "2 months ago",
  },
  {
    value: moment().add("month", -6).valueOf(),
    label: "6 months ago",
  },
];

const optionsPrice = [
  {
    value: 100,
    label: "$100",
  },
  {
    value: 200,
    label: "$200",
  },
];

export const ProductAndProductVariantCleanerOptions = ({
  tab,
}: {
  tab: number;
}) => {
  const maxTitle = useMemo(() => {
    return "Item price is greater than";
  }, [tab]);

  const minTitle = useMemo(() => {
    return "Item price is less than";
  }, [tab]);

  const translateCustomValueToMonths = (months: number) => {
    return moment().add("months", -months).valueOf();
  };

  return (
    <StyledComponent>
      <ProductAndProductVariantOptionInput
        translateCustomValue={translateCustomValueToMonths}
        customOptionLabel="Other number of months ago"
        title="Last sold more than"
        options={optionsMonths}
        keyToUpdate={"lastSoldOptions"}
      />
      <ProductAndProductVariantOptionInput
        customOptionLabel="Other item price"
        title={maxTitle}
        options={optionsPrice}
        keyToUpdate={"itemPriceGreaterThanOption"}
      />
      <ProductAndProductVariantOptionInput
        customOptionLabel="Other item price"
        title={minTitle}
        options={optionsPrice}
        keyToUpdate={"itemPriceLessThanOption"}
      />
    </StyledComponent>
  );
};

const StyledComponent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
