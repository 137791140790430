import { Button, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import styled from "styled-components";
import { ButterflyLabel, SectionContainer } from "../../reusable-things";
import { saleManagerUI } from "../../sale-manager-ui";

const Input = styled("input")({
  display: "none",
});

export const SalesBannerImageUploader = observer(() => {
  const { bannerImageURL } = saleManagerUI;
  const [highlightDragAndDropBox, setHighlightDragAndDropBox] = useState(false);

  const fileUpload = (event: any) => {
    event.stopPropagation();
    if (!event.target || !event.target.files) return;
    saleManagerUI.uploadBannerImage(event.target.files[0]);
  };

  return (
    <StyledComponent>
      <ButterflyLabel>Upload sale banner image</ButterflyLabel>
      <DragAndDropBox
        onDragLeave={() => setHighlightDragAndDropBox(false)}
        onDragOver={() => setHighlightDragAndDropBox(true)}
        onChange={fileUpload}
        $highLightBox={highlightDragAndDropBox}
      >
        {bannerImageURL ? (
          <BannerImage src={bannerImageURL} />
        ) : (
          <>
            <Text>Drag File Here</Text>
            <br />
            <Text>Or</Text>
          </>
        )}
        <br />
        <label htmlFor="contained-button-file">
          <Input
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            onChange={fileUpload}
          />
          <StyledButton component="span" variant="outlined">
            {bannerImageURL ? "replace image" : "browse computer"}
          </StyledButton>
        </label>
      </DragAndDropBox>
    </StyledComponent>
  );
});

const StyledComponent = styled.div`
  ${SectionContainer};
  display: block;
`;

type DragAndDropBoxProps = {
  $highLightBox: boolean;
};

const DragAndDropBox = styled.div<DragAndDropBoxProps>`
  border: solid thin
    ${({ $highLightBox }) => ($highLightBox ? "lightblue" : "#e8e8e8")};
  border-radius: 3px;
  width: 100%;
  margin-top: 0.5rem;
  padding: 2rem 0;
`;

const Text = styled(Typography)`
  text-align: center;
`;

const StyledButton = styled(Button)`
  && {
    margin: 0 auto;
    display: block;
    width: fit-content;
  }
` as typeof Button;

const BannerImage = styled.img`
  width: 50%;
  margin: 0 auto;
  height: auto;
  display: block;
`;