import {
  collection,
  getDocs,
  getFirestore,
  Query,
  query,
  where,
} from "firebase/firestore";
import { makeAutoObservable } from "mobx";
import {
  PRODUCT_COLLECTION_NAME,
  PRODUCT_VARIANTS_COLLECTION_NAME,
} from "../../../App";
import {
  extractNumeralIdFromShopifyId,
  removeDuplicates,
  turnFirestoreCollectionIntoArray,
} from "../../universal-utility-functions/";
import toastr from "toastr";
import { handleGeneralError } from "../../universal-utility-functions/src/errorHandling";
import { TAGS_COLLECTION_NAME } from "../../sale-manager-ui";
import { salesCoreURL, shopifyStoreUrl } from "../../../config";
import { appLevelUI } from "../../app-level-UI";

export const handleClickGoToPage = (
  uid: string,
  type: string,
  productId?: string
) => {
  const adminURL = `${shopifyStoreUrl}/admin`;

  if (type === "product") {
    window.open(`${adminURL}/products/${uid}`, "_blank")?.focus();
    return;
  }
  window
    .open(`${adminURL}/products/${productId}/variants/${uid}`, "_blank")
    ?.focus();
}; 

const SkuWithBadImageRatioBooleanKey = "wrongImageRatio";
const SkuWithBadImageBooleanKey = "invalidImage";

export class CleanerPageUI {
  tab: 0 | 1 | 2; // 0 = image cleaner : 1 = sku cleaner : 2 = variant cleaner

  // Image Cleaner State
  cleanSkusWithImagesWithBadRatio: boolean;
  cleanSkusImagesWithImagesThatAreNull: boolean;

  // product/product variant state (-1 value means invalid custom option)
  lastSoldOptions?: number;
  itemPriceLessThanOption?: number;
  itemPriceGreaterThanOption?: number;

  imageCleanerSkusToDisplay: any[];
  productVariantsToDisplay: any[];
  productsToDisplay: any[];

  tagsToAddToProductsAndVariants: string[];

  shopsTags: string[];

  loadingTable: boolean;
  loadingAddingTags: boolean;

  constructor() {
    this.tab = 0;
    this.loadingTable = false;

    this.cleanSkusWithImagesWithBadRatio = false;
    this.cleanSkusImagesWithImagesThatAreNull = false;

    this.lastSoldOptions = undefined;
    this.itemPriceLessThanOption = undefined;
    this.itemPriceGreaterThanOption = undefined;

    this.imageCleanerSkusToDisplay = [];
    this.productVariantsToDisplay = [];
    this.productsToDisplay = [];

    this.tagsToAddToProductsAndVariants = [];

    this.shopsTags = [];

    this.loadingAddingTags = false;

    this.initlize();

    makeAutoObservable(this, {}, { autoBind: true });
  }

  async initlize() {
    const firestore = getFirestore();

    const tagsCollection = await getDocs(
      query(collection(firestore, TAGS_COLLECTION_NAME))
    );
    const tags = tagsCollection.docs.map((doc) => doc.data());
    this.shopsTags = removeDuplicates(tags.map((tag) => tag.name));
  }

  async addTags(productsToAddTagsTo: string[]) {
    try {
      this.loadingAddingTags = true;
      const res = await fetch(`${salesCoreURL}/add-tags`, {
        body: JSON.stringify({
          authToken: appLevelUI.userToken,
          tagsToAdd: this.tagsToAddToProductsAndVariants,
          productsToAddTagsTo: productsToAddTagsTo,
        }),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status !== 200) throw "Failed to add tags";
      this.generateTableForProducts();
      this.loadingAddingTags = false;
    } catch (err) {
      this.loadingAddingTags = false;
      handleGeneralError("cleanerPageUI", "addTags", err);
    }
  }

  resetSelections() {
    this.lastSoldOptions = undefined;
    this.itemPriceLessThanOption = undefined;
    this.itemPriceGreaterThanOption = undefined;
  }

  generateSkuTable() {
    switch (this.tab) {
      case 0:
        this.generateSkuTableForImageCleaner();
        break;
      case 1:
        this.generateTableForProducts();
        break;
      case 2:
        this.generateTableForVariants();
        break;
    }
  }

  async generateTableForProducts() {
    try {
      this.loadingTable = true;
      if (
        this.lastSoldOptions === undefined &&
        this.itemPriceLessThanOption === undefined &&
        this.itemPriceGreaterThanOption === undefined
      ) {
        throw "Please select at least one option";
      }
      const firestore = getFirestore();
      const productCollectionRef = await collection(
        firestore,
        PRODUCT_COLLECTION_NAME
      );

      let qualifiedProductSkus = [];

      if (this.lastSoldOptions !== undefined) {
        const products = await getDocs(
          query(
            productCollectionRef,
            where("lastPurchased", ">=", this.lastSoldOptions)
          )
        );
        qualifiedProductSkus = turnFirestoreCollectionIntoArray(products);
      } else {
        const products = await getDocs(query(productCollectionRef));
        qualifiedProductSkus = turnFirestoreCollectionIntoArray(products);
      }

      if (this.itemPriceLessThanOption !== undefined) {
        qualifiedProductSkus = qualifiedProductSkus.filter(
          (product) =>
            this.itemPriceLessThanOption &&
            product.maxVariantPrice <= this.itemPriceLessThanOption
        );
      }

      if (this.itemPriceGreaterThanOption !== undefined) {
        qualifiedProductSkus = qualifiedProductSkus.filter(
          (product) =>
            this.itemPriceGreaterThanOption &&
            product.maxVariantPrice >= this.itemPriceGreaterThanOption
        );
      }

      const productSkus = qualifiedProductSkus.map((sku) => {
        return {
          ...sku,
          name: sku.title,
          tags: sku.tags ? sku.tags : [],
        };
      });

      this.productsToDisplay = productSkus;
      this.loadingTable = false;
    } catch (err) {
      this.loadingTable = false;
      handleGeneralError("cleanerPageUI", "generateTableForProducts", err);
    }
  }

  async generateSkuTableForImageCleaner() {
    try {
      this.loadingTable = true;
      if (
        !this.cleanSkusWithImagesWithBadRatio &&
        !this.cleanSkusImagesWithImagesThatAreNull
      ) {
        toastr.error("Please select at least one filter option.");
        return;
      }

      const firestore = getFirestore();
      const productVariantCollectionRef = await collection(
        firestore,
        PRODUCT_VARIANTS_COLLECTION_NAME
      );
      const productCollectionRef = await collection(
        firestore,
        PRODUCT_COLLECTION_NAME
      );
      let productVariantDocs = null as any as Query;
      let productDocs = null as any as Query;

      if (
        this.cleanSkusWithImagesWithBadRatio &&
        this.cleanSkusImagesWithImagesThatAreNull
      ) {
        productVariantDocs = query(
          productVariantCollectionRef,
          where(SkuWithBadImageBooleanKey, "==", true)
        );
        productDocs = query(
          productCollectionRef,
          where(SkuWithBadImageBooleanKey, "==", true)
        );
      } else if (this.cleanSkusWithImagesWithBadRatio) {
        productVariantDocs = query(
          productVariantCollectionRef,
          where(SkuWithBadImageRatioBooleanKey, "==", true)
        );
        productDocs = query(
          productCollectionRef,
          where(SkuWithBadImageRatioBooleanKey, "==", true)
        );
      } else if (this.cleanSkusImagesWithImagesThatAreNull) {
        productVariantDocs = query(
          productVariantCollectionRef,
          where("image", "==", null)
        );
        productDocs = query(productCollectionRef, where("image", "==", null));
      }

      const productVariantSkus = turnFirestoreCollectionIntoArray(
        await getDocs(productVariantDocs)
      ).map((sku) => {
        return {
          ...sku,
          type: "productVariant",
          name: sku.displayName,
          productId: extractNumeralIdFromShopifyId(sku.product.path),
        };
      });

      const productSkus = turnFirestoreCollectionIntoArray(
        await getDocs(productDocs)
      ).map((sku) => {
        return {
          ...sku,
          type: "product",
          name: sku.title,
          barcode: "N/A",
          compareAtPrice: "N/A",
        };
      });
      this.imageCleanerSkusToDisplay = [
        ...productVariantSkus,
        ...productSkus,
      ].sort();

      this.loadingTable = false;
    } catch (err) {
      this.loadingTable = false;
      toastr.error("Failed to generate table.");
    }
  }

  async generateTableForVariants() {
    try {
      this.loadingTable = true;
      if (
        this.lastSoldOptions === undefined &&
        this.itemPriceLessThanOption === undefined &&
        this.itemPriceGreaterThanOption === undefined
      ) {
        throw "Please select at least one option";
      }
      const firestore = getFirestore();
      const productVariantCollectionRef = await collection(
        firestore,
        PRODUCT_VARIANTS_COLLECTION_NAME
      );

      let qualifiedProductVariants = [];

      if (this.lastSoldOptions !== undefined) {
        const products = await getDocs(
          query(
            productVariantCollectionRef,
            where("lastPurchased", ">=", this.lastSoldOptions)
          )
        );
        qualifiedProductVariants = turnFirestoreCollectionIntoArray(products);
      } else {
        const products = await getDocs(query(productVariantCollectionRef));
        qualifiedProductVariants = turnFirestoreCollectionIntoArray(products);
      }

      // Price range filter
      if (this.itemPriceLessThanOption && this.itemPriceGreaterThanOption) {
        qualifiedProductVariants = qualifiedProductVariants.filter(
          (product) =>
            this.itemPriceLessThanOption &&
            this.itemPriceGreaterThanOption &&
            product.compareAtPrice >= this.itemPriceGreaterThanOption &&
            product.compareAtPrice <= this.itemPriceLessThanOption
        );
      } else if (this.itemPriceLessThanOption) {
        qualifiedProductVariants = qualifiedProductVariants.filter(
          (product) =>
            this.itemPriceLessThanOption &&
            product.compareAtPrice <= this.itemPriceLessThanOption
        );
      } else if (this.itemPriceGreaterThanOption) {
        qualifiedProductVariants = qualifiedProductVariants.filter(
          (product) =>
            this.itemPriceGreaterThanOption &&
            product.compareAtPrice >= this.itemPriceGreaterThanOption
        );
      }

      const variantSkus = qualifiedProductVariants.map((sku) => {
        return {
          ...sku,
          name: sku.displayName,
          productId: sku.product.id
        };
      });

      this.productVariantsToDisplay = variantSkus;
      this.loadingTable = false;
    } catch (err) {
      this.loadingTable = false;
      handleGeneralError("cleanerPageUI", "generateTableForVariants", err);
    }
  }
}

export const cleanerPageUI = new CleanerPageUI();
