import React, { useEffect } from "react";
import { Router, Route, Switch } from "react-router";
import { appLevelUI } from "./packages/app-level-UI";
import { LoginPage } from "./packages/login-page";
import { HomePage } from "./packages/home-page";
import { ThemeProvider } from "styled-components";
import { CleanerPage } from "./packages/cleaner-page";
import { SaleManagerPage } from "./packages/sale-manager-page";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SaleManagerTablePage } from "./packages/sale-manager-table-page";
import { ResetPasswordPage } from "./packages/reset-password-page";

export const PRODUCT_VARIANTS_COLLECTION_NAME = "ProductVariants";
export const PRODUCT_COLLECTION_NAME = "Products";

const theme = {
  blue100: "#EDF4FB",
  blue200: "#1976D2",
  dark100: "#1976D2",
  dark200: "#2E2E2E",
  dark300: "#464646",
  grey100: "#747474",
  grey200: "#A2A2A2",
  grey300: "#D1D1D1",
  grey400: "#F1F1F1",
};

function App() {
  const { history } = appLevelUI;

  useEffect(() => {
    appLevelUI.initialize();
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Router history={history}>
            <Switch>
              <Route path="/login" component={LoginPage} />
              <Route path="/home" component={HomePage} />
              <Route path="/cleaner" component={CleanerPage} />
              <Route path="/sale-manager" component={SaleManagerPage} />
              <Route path="/sale-manager-table" component={SaleManagerTablePage} />
              <Route path="/reset-password" component={ResetPasswordPage} />
              <Route component={HomePage} />
            </Switch>
          </Router>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
