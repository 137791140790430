import { ArrowBack } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

type BackButtonProps = {
  to: string;
};

export const BackButton = ({ to }: BackButtonProps) => {
  return (
    <StyledLink to={to}>
      <ArrowBack />
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  margin: auto 0;
    margin-right: 0.5rem;
`;