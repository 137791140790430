import { Button, Typography } from "@mui/material";
import { getAuth } from "firebase/auth";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const HomePage = () => {
  const logout = () => {
    const auth = getAuth();
    auth.signOut();
  };

  return (
    <StyledComponent>
      <Title>Butterfly Admin Panel</Title>
      <LinksContainer>
        <LinkContainer>
          <LinkTitle variant="h4">Cleaner</LinkTitle>
          <LinkButton>
            <Link to="/cleaner">Take me there</Link>
          </LinkButton>
        </LinkContainer>
        <LinkContainer>
          <LinkTitle variant="h4">Sale Manager</LinkTitle>
          <LinkButton>
            <Link to="/sale-manager-table">Take me there</Link>
          </LinkButton>
        </LinkContainer>
      </LinksContainer>
      <LogoutButton onClick={logout}>Logout</LogoutButton>
    </StyledComponent>
  );
};

const StyledComponent = styled.div`
  background-color: #eaeaea;
  height: 99vh;
  width: 95vw;
  padding: 0 2.5vw;
  padding-top: 1vh;
`;

const Title = styled(Typography)`
  && {
    font-size: 2.5rem;
    margin-left: 5%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 10rem;
`;

const LinkContainer = styled.div`
  width: 40%;
  background-color: white;
  border-radius: 1rem;
`;

const LinkTitle = styled(Typography)`
  && {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const LinkButton = styled(Button)`
  && {
    margin: 0 auto;
    display: block;
    margin-bottom: 1rem;
  }
`;

const LogoutButton = styled(Button)`
  && {
    position: absolute;
    top: 2rem;
    left: 2rem;
  }
`;
