import React from "react";
import moment from "moment";
import styled from "styled-components";

type InventoryQtyCellProps = {
  inventoryQuantity: number | null;
  inventoryUpdatedAt: string | null;
};

export const InventoryQtyCell = ({
  inventoryQuantity,
  inventoryUpdatedAt,
}: InventoryQtyCellProps) => {
  return (
    <StyledComponent>
      {inventoryQuantity ? (
        <>
          <span>{inventoryQuantity}</span>
          <span className="updated-at">
            As of {moment(inventoryUpdatedAt).calendar()}
          </span>
        </>
      ) : (
        "N/A"
      )}
    </StyledComponent>
  );
};

type StyledComponentProps = {};

const StyledComponent = styled.div<StyledComponentProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: hidden;
  text-align: center;

  .updated-at {
    font-size: 0.65rem;
  }
`;
