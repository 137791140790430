import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { cleanerPageUI } from "../../cleaner-page-ui";

export const ImageCleanerOptions = () => {
  return (
    <StyledComponent>
      <FormControlLabel
        control={
          <Checkbox
            onClick={() =>
              (cleanerPageUI.cleanSkusWithImagesWithBadRatio =
                !cleanerPageUI.cleanSkusWithImagesWithBadRatio)
            }
          />
        }
        label="Image dimensions not 1:1"
      />
      <FormControlLabel
        control={
          <Checkbox
            onClick={() =>
              (cleanerPageUI.cleanSkusImagesWithImagesThatAreNull =
                !cleanerPageUI.cleanSkusImagesWithImagesThatAreNull)
            }
          />
        }
        label="Image doesn’t exist"
      />
    </StyledComponent>
  );
};

const StyledComponent = styled.div``;
