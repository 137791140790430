import { Button } from "@mui/material";
import React from "react";
import styled from "styled-components";

type SaleManagerPageButtonProps =
  | {
      children: React.ReactNode;
      className?: string;
    }
  | any;

export const SaleManagerPageButton = (props: SaleManagerPageButtonProps) => {
  const { children } = props;
  return <StyledButton {...props}>{children}</StyledButton>;
};

const StyledButton = styled(Button)`
  && {
    margin-top: 1rem;
  }
`;