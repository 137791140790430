import 'firebase/compat/firestore';
import { initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectAuthEmulator, getAuth } from "firebase/auth";

import { config, getCurrentEnvironment } from "./config";
import { connectStorageEmulator, getStorage } from 'firebase/storage';

// TODO figure out dev ops stuff. This should be fine for local development for now though.
export const app = initializeApp(config);
const auth = getAuth(app);
const firestore = getFirestore(app);
export const storage = getStorage(app);

const currentEnv = getCurrentEnvironment();

console.log(`Connected to ${currentEnv} environment`);

if (currentEnv === "development") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectStorageEmulator(storage, "localhost", 9199);
}

