import { Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { appLevelUI } from "../../app-level-UI";

export const LoginPage = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  return (
    <StyledComponent>
      <Content>
        <Title variant="h1">Welcome To Butterfly Admin</Title>
        <StyledTextField
          fullWidth
          placeholder="email"
          value={email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(event.target.value)
          }
        />
        <StyledTextField
          fullWidth
          placeholder="password"
          type="password"
          value={password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(event.target.value)
          }
        />
        <LoginButton
          size="large"
          onClick={() => appLevelUI.login(email, password)}
        >
          Login
        </LoginButton>
        <StyledLink to="/reset-password">
          <ResetPasswordButton size={"small"} variant="text">
            Reset Password
          </ResetPasswordButton>
        </StyledLink>
      </Content>
    </StyledComponent>
  );
};

const StyledComponent = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #eaeaea;
`;

const Content = styled.div`
  width: 30rem;
  margin: auto;
  background-color: white;
  border-radius: 0.5rem;
  padding-bottom: 1rem;
`;

const Title = styled(Typography)`
  && {
    font-size: 1.5rem;
    margin-left: 5%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const StyledTextField = styled(TextField)`
  && {
    margin: 0 auto;
    margin-bottom: 2rem;
    width: 90%;
    display: block;
  }
`;

const ResetPasswordButton = styled(Button)`
  && {
    margin-left: 5%;
    display: block;
  }
`;

const LoginButton = styled(Button)`
  && {
    margin-left: 5%;
    margin-top: -1rem;
    display: block;
  }
`;

const StyledLink = styled(Link)`
  && {
    text-decoration: none;
  }
`;