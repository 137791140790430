import { TextField, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { ButterflyLabel } from "./ButterflyInputLabel";

type ButterflyInputProps = {
    label?: string;
    value: any;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => any;
    className?: string;
    error?: boolean;
    placeholder?: string;
    disabled?: boolean;
}

export const ButterflyInput = ({disabled, placeholder, error, label, value, onChange, className}: ButterflyInputProps) => {
  return (
    <StyledComponent className={className}>
      {label && <ButterflyLabel>{label}</ButterflyLabel>}
      <TextField disabled={disabled} variant="outlined" placeholder={placeholder} error={error} fullWidth value={value} onChange={(event) => onChange(event)} />
    </StyledComponent>
  )
}

const StyledComponent = styled.div``;
