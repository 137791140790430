import { Checkbox, FormControlLabel, Radio } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { cleanerPageUI } from "../../cleaner-page-ui";
import { ButterflyInput, convertStringToNumber } from "../../reusable-things";

type ProductAndProductVariantOptionInputProps = {
  options: { value: number; label: string }[];
  keyToUpdate: string;
  title: string;
  customOptionLabel: string;
  translateCustomValue?: (customValue: number) => number;
};

export const ProductAndProductVariantOptionInput = observer(
  ({
    options,
    keyToUpdate,
    customOptionLabel,
    title,
    translateCustomValue = (customValue: number) => customValue,
  }: ProductAndProductVariantOptionInputProps) => {
    const [optionSelected, setOptionSelected] = useState(false);
    const [customOptionValue, setCustomOptionValue] = useState("");
    const [customOptionSelected, setCustomOptionSelected] = useState(false);

    const {
      lastSoldOptions,
      itemPriceLessThanOption,
      itemPriceGreaterThanOption,
    } = cleanerPageUI;

    const optionTrueValue = useMemo(() => {
      switch (keyToUpdate) {
        case "lastSoldOptions":
          return lastSoldOptions;
        case "itemPriceLessThanOption":
          return itemPriceLessThanOption;
        case "itemPriceGreaterThanOption":
          return itemPriceGreaterThanOption;
      }
      return undefined;
    }, [
      lastSoldOptions,
      itemPriceGreaterThanOption,
      itemPriceLessThanOption,
      keyToUpdate,
    ]);

    useEffect(() => {
      updateOption();
    }, [optionSelected]);

    useEffect(() => {
      updateCustomOption();
      if (customOptionValue === "" || !customOptionValue) {
        setCustomOptionSelected(false);
      } else {
        setCustomOptionSelected(true);
      }
    }, [customOptionValue]);

    // Uncheck checkbox if no sub-options is selected
    useEffect(() => {
      if (optionTrueValue) {
        setOptionSelected(true);
      } else {
        setOptionSelected(false);
        setCustomOptionSelected(false);
        setCustomOptionValue("");
      }
    }, [optionTrueValue]);

    const updateOption = (optionSelected?: number) => {
      setCustomOptionSelected(false);
      setCustomOptionValue("");
      if (optionTrueValue && !optionSelected) {
        updateTrueValue(undefined);
      }
      if (optionSelected) {
        updateTrueValue(optionSelected);
        return;
      }
    };

    const updateCustomOption = () => {
      if (!customOptionValue) return;
      updateTrueValueWithCustomValue();
    };

    const updateCustomOptionSelected = () => {
      if (customOptionSelected) return;
      updateTrueValueWithCustomValue();
      setCustomOptionSelected(!customOptionSelected);
    };

    const updateTrueValueWithCustomValue = () => {
      if (isNaN(convertStringToNumber(customOptionValue))) {
        updateTrueValue(-1);
        return;
      }
      updateTrueValue(
        translateCustomValue(convertStringToNumber(customOptionValue))
      );
    };

    const updateTrueValue = (newValue: number | undefined) => {
      switch (keyToUpdate) {
        case "itemPriceLessThanOption":
          cleanerPageUI.itemPriceLessThanOption = newValue;
          break;
        case "lastSoldOptions":
          cleanerPageUI.lastSoldOptions = newValue;
          break;
        case "itemPriceGreaterThanOption":
          cleanerPageUI.itemPriceGreaterThanOption = newValue;
          break;
      }
    };

    return (
      <StyledComponent>
        <FormControlLabelStyled
          control={
            <Checkbox
              onClick={() => setOptionSelected(!optionSelected)}
              checked={optionSelected}
            />
          }
          label={title}
        />
        <OptionsContainer>
          {options.map((option) => (
            <FormControlLabelStyled
              control={
                <Radio
                  disabled={!optionSelected}
                  checked={option.value === optionTrueValue}
                  onChange={() => updateOption(option.value)}
                />
              }
              label={option.label}
            />
          ))}
          <FormControlLabelStyled
            control={
              <Radio
                disabled={!optionSelected}
                checked={customOptionSelected}
                onChange={updateCustomOptionSelected}
              />
            }
            label={customOptionLabel}
          />
          <ButterflyInput
            error={optionTrueValue === -1 ? true : undefined}
            placeholder="eg: 7"
            value={customOptionValue}
            onChange={(event) => setCustomOptionValue(event.target.value)}
          />
        </OptionsContainer>
      </StyledComponent>
    );
  }
);

const StyledComponent = styled.div`
  display: block;
  width: 30%;
`;

const FormControlLabelStyled = styled(FormControlLabel)`
  display: block;
  width: 100%;
`;

const OptionsContainer = styled.div`
  margin-left: 2rem;
`;
