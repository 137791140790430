import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import React, { useMemo, useEffect } from "react";
import styled from "styled-components";
import { cleanerPageUI } from "../../cleaner-page-ui";
import { observer } from "mobx-react-lite";
import { ImageCleanerTable } from "./ImageCleanerTable";
import { BackButton } from "../../reusable-things/src/BackButton";
import { ImageCleanerOptions } from "./ImageCleanerOptions";
import { ProductAndProductVariantCleanerOptions } from "./ProductAndProductVariantCleanerOptions";
import { SkuCleanerTable } from "./SkuCleanerTable";
import { VariantCleanerTable } from "./VariantCleanerTable";

export const CleanerPage = observer(() => {
  const { tab, resetSelections } = cleanerPageUI;
  useEffect(() => {
    resetSelections();
  }, [tab]);

  return (
    <StyledComponent>
      <TitleAndBackButtonContainer>
        <BackButton to="/" />
        <Title variant="h4">Cleaner</Title>
      </TitleAndBackButtonContainer>
      <Content sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tab}
            onChange={(event, newValue) => {
              cleanerPageUI.tab = newValue;
            }}
            aria-label="basic tabs example"
          >
            <Tab label="Image Cleaner" {...a11yProps(0)} />
            <Tab label="Sku Cleaner" {...a11yProps(1)} />
            <Tab label="Variant Cleaner" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <FilterContent>
          <FilterTitle>Apply filters to pull SKUs by: </FilterTitle>
          <TabPanel value={tab} indexs={[0]}>
            <ImageCleanerOptions />
          </TabPanel>
          <TabPanel value={tab} indexs={[1, 2]}>
            <ProductAndProductVariantCleanerOptions tab={tab} />
          </TabPanel>
          <Button
            onClick={() => cleanerPageUI.generateSkuTable()}
            variant="contained"
          >
            GENERATE SKU TABLE
          </Button>
        </FilterContent>
      </Content>
      <TabPanel value={tab} indexs={[0]}>
        <ImageCleanerTable />
      </TabPanel>
      <TabPanel value={tab} indexs={[1]}>
        <SkuCleanerTable />
      </TabPanel>
      <TabPanel value={tab} indexs={[2]}>
        <VariantCleanerTable />
      </TabPanel>
    </StyledComponent>
  );
});

const StyledComponent = styled.div`
  width: 75rem;
  padding-top: 5rem;
  margin: auto;
`;

const TitleAndBackButtonContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

const Title = styled(Typography)`
  && {
    font-size: 1.25rem;
    font-weight: bolder;
  }
`;

const FilterContent = styled.div`
  width: 95%;
  margin: auto;
  margin-bottom: 2rem;
`;

const FilterTitle = styled(Typography)`
  && {
    margin-top: 1rem;
  }
`;

const Content = styled(Box)`
  border-radius: 0.25rem;
  border: solid thin ${({ theme }) => theme.grey300};
  padding: 0 0.5rem;
`;

function TabPanel(props: { children: any; indexs: number[]; value: any }) {
  const { children, value, indexs, ...other } = props;

  const isActivePanel = useMemo(() => {
    if (indexs.find((index) => index === value) !== undefined) return true;
    return false;
  }, [indexs, value]);

  return (
    <div role="tabpanel" hidden={!isActivePanel} {...other}>
      {isActivePanel && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
