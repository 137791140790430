import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { saleManagerUI } from "../../sale-manager-ui";

type LogsProps = {};

export const Logs = observer(() => {
  const { logs, showLogs } = saleManagerUI;
  return (
    <>
      <Button
        onClick={() => (saleManagerUI.showLogs = !showLogs)}
        variant={"text"}
      >
        View Logs
      </Button>
      {showLogs && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Time Stamp</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Info</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log) => {
                return (
                  <TableRow>
                    <TableCell component="th">
                      {moment(log.timeStamp).calendar()}
                    </TableCell>
                    <TableCell>{log.type}</TableCell>
                    <TableCell>{log.info}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
});
