import { collection, getDocs, getFirestore, query } from "firebase/firestore";
import { makeAutoObservable } from "mobx";
import { salesCoreURL } from "../../../config";
import { app } from "../../../firebaseSetup";
import { turnFirestoreCollectionIntoArray } from "../../universal-utility-functions";
import { handleGeneralError } from "../../universal-utility-functions/src/errorHandling";
import { OverideObject, SALE_COLLECTION_NAME, Status } from "./saleManagerUI";
import toastr from "toastr";

type SaleData = {
  uid: string;
  name: string;
  category: string;
  end: number;
  start: number;
  discount: number;
  status?: Status;
  progressPercentage?: number;
  tagsToApplyDiscountToo: string[]; // TODO make sure data from DB is not undefined or null
  tagGroupsToApplyDiscountToo: { tags: string[] }[];
  individualTagsToOveride: OverideObject[];
  tagGroupsToOveride: OverideObject[];
};

export class SaleManagerTableUI {
  data: SaleData[];
  loading: boolean;

  constructor() {
    this.loading = true;
    this.data = [];
    this.initilize();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async initilize() {
    try {
      this.loading = true;
      const firestore = getFirestore(app);
      const rawData = turnFirestoreCollectionIntoArray(
        await getDocs(query(collection(firestore, SALE_COLLECTION_NAME)))
      );
      this.data = rawData;
      this.loading = false;
    } catch (err) {
      this.loading = false;
    }
  }

  async triggerSales() {
    try {
      this.loading = true;
      fetch(`${salesCoreURL}/trigger-sales`);
      this.initilize();
      this.loading = false;
      toastr.success(
        "Sales will start processing, please check back in a few minutes."
      );
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (err) {
      handleGeneralError(
        "Sale Manager Table UI",
        "initilize",
        err,
        "Failed to trigger sales"
      );
    }
  }
}

export const saleManagerTableUI = new SaleManagerTableUI();
