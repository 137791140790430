import toastr from "toastr";

export const handleGeneralError = (fileName: string, functionName: string, error: any, errorMsg?: string) => {
  console.error(`Caught Error ${fileName} ${functionName}: `);
  console.error(error);
  if (errorMsg) {
    toastr.error(errorMsg);
    return;
  }
  if (typeof error === "string") {
    toastr.error(error);
    return;
  }
  toastr.error("There is an expected error, please try again later or contact an admin.");
};
