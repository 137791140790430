import {
  Add,
  ArrowBack,
  Block,
  Delete,
  Edit,
  Save,
  Stop,
} from "@mui/icons-material";
import {
  Button,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { BackButton } from "../../reusable-things/src/BackButton";
import { saleManagerUI } from "../../sale-manager-ui";
import { BasicInfoInput } from "./BasicInfoInput";
import { ItemsOnSaleModal } from "./ItemsOnSaleModal";
import { Logs } from "./Logs";
import { OverideTagsInput } from "./OverideTagsInput";
import { SaleManagerHeader } from "./SaleMangerHeader";
import { TagsInput } from "./TagsInput";
import { TimeRangeInput } from "./TimeRangeInput";
import { SalesBannerImageUploader } from "./SalesBannerImageUploader";

export const SaleManagerPage = observer(() => {
  const { loading, status, editMode, startSaleRightAWay } = saleManagerUI;

  useEffect(() => {
    saleManagerUI.initilize();
  }, []);

  const backgroundColor = useMemo(() => {
    if (editMode) return "#EFE8F9";
    switch (status) {
      case "draft":
        return "#EFE8F9";
      case "scheduled":
        return "#FDF8EB";
      case "live":
        return "#EBF4F2";
      case "cancelled":
        return "#FDEDEF";
      case "completed":
        return "#E9F2FA";
      default:
        return "#EFE8F9";
    }
  }, [editMode, status]);

  if (loading) {
    return <>Loading...</>;
  }
  return (
    <StyledComponent>
      <ItemsOnSaleModal />
      <SaleManagerHeader />
      <Content $backgroundColor={backgroundColor}>
        <BasicInfoInput />
        <TagsInput />
        <OverideTagsInput />
        <TimeRangeInput />
        <SalesBannerImageUploader />
      </Content>
      {(status === "draft" || editMode) && (
        <CreateAndSaveButtons>
          <Button
            onClick={() => saleManagerUI.saleOrCreate(false)}
            variant={"contained"}
          >
            {startSaleRightAWay ? "start sale" : "schedule sale event"}
          </Button>
          {!startSaleRightAWay && (
            <StyledSaveAsDraft
              onClick={() => saleManagerUI.saleOrCreate(true)}
              variant={"outlined"}
            >
              save as draft
            </StyledSaveAsDraft>
          )}
        </CreateAndSaveButtons>
      )}
      <Logs />
    </StyledComponent>
  );
});

const StyledComponent = styled.div`
  width: 75rem;
  padding-top: 5rem;
  margin: auto;
  margin-bottom: 4rem;
`;

const Title = styled(Typography)`
  && {
    font-size: 1.25rem;
    margin: auto 0;
    font-weight: bolder;
    margin-right: auto;
  }
`;

type ContentProps = {
  $backgroundColor: string;
};

const Content = styled.div<ContentProps>`
  width: 95%;
  padding: 2.5%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: 0.5rem;
`;

const CreateAndSaveButtons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const StyledSaveAsDraft = styled(Button)`
  && {
    margin-left: 1rem;
  }
`;

const TitleAndActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

const ActionButton = styled(Button)`
  && {
    height: 3rem;
    margin: 0 1rem;
  }
`;
