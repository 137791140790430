import config from "./firebaseJSONConfig.json";
const stagingHosts = [
  "butterfly.dokku.explorator.ca",
  "butterfly-beads-staging.web.app",
  "butterfly-beads-staging.firebaseapp.com",
];

const productionHosts = [
  "badal-butterflybeads.web.app",
  "badal-butterflybeads.firebaseapp.com"
] as any[];

const getCurrentEnvironment = () => {
  if (stagingHosts.includes(window.location.host)) return "staging";
  if (productionHosts.includes(window.location.host)) return "production";
  return "development";
};

let salesCoreURL = "http://localhost:2424";
let shopifyStoreUrl = "https://butterfly-beads-staging.myshopify.com"

if(getCurrentEnvironment() === "staging") {
  salesCoreURL = "https://butterfly-sales-core-bxqheslhja-nn.a.run.app";
} else if(getCurrentEnvironment() === "production") {
  salesCoreURL = "https://butterfly-sales-core-ia6hcq363a-nn.a.run.app";
  shopifyStoreUrl = "https://butterflybeads.myshopify.com"
}

const firebaseConfig = { ...config };

if (getCurrentEnvironment() === "staging") {
  firebaseConfig.apiKey = "AIzaSyCG8cdZb8JLywbEbu_aSwZ3XFGIGPRil5U";
  firebaseConfig.authDomain = "butterfly-beads-staging.firebaseapp.com";
} else if (getCurrentEnvironment() === "production") {
  firebaseConfig.apiKey = "AIzaSyBgZ-3j5pzv8F2Ttbx3xaAa910ZDU4gBUc";
  firebaseConfig.authDomain = "badal-butterflybeads.firebaseapp.com";
  firebaseConfig.projectId = "badal-butterflybeads";
  firebaseConfig.storageBucket = "badal-butterflybeads.appspot.com";
  firebaseConfig.appId = "1:72815169052:web:276f58e4e5da6881f4d9d9";
}

// eslint-disable-next-line import/no-mutable-exports
let firebaseFunctionUrl =
  "http://localhost:5001/butterfly-beads-staging/northamerica-northeast1";

if (getCurrentEnvironment() === "staging") {
  firebaseFunctionUrl =
    "https://northamerica-northeast1-butterfly-beads-staging.cloudfunctions.net";
} else if (getCurrentEnvironment() === "production") {
  firebaseFunctionUrl = "https://us-central1-badal-butterflybeads.cloudfunctions.net";
}

export {
  firebaseConfig as config,
  firebaseFunctionUrl as backendUrl,
  getCurrentEnvironment,
  salesCoreURL,
  shopifyStoreUrl
};
