import {
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
  collection,
  query,
} from "firebase/firestore";
import { makeAutoObservable } from "mobx";
import { app, storage } from "../../../firebaseSetup";
import { v4 as uuidv4 } from "uuid";
import { appLevelUI } from "../../app-level-UI";
import { removeDuplicates, removeUndefinedAndNullFieldFromObject } from "../../universal-utility-functions";
import toastr from "toastr";
import { getCurrentEnvironment, salesCoreURL } from "../../../config";
import moment from "moment";
import { handleGeneralError } from "../../universal-utility-functions/src/errorHandling";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";

export const TAGS_COLLECTION_NAME = "Tags";
export const CUSTOM_TYPES_COLLECTION_NAME = "CustomTypes";
export const SALE_COLLECTION_NAME = "Sales";

export type OverideObject = {
  noSale?: boolean;
  amount?: number;
  tags: string[];
};

type Log = {
  timeStamp: number;
  type: string;
  info: string;
};

export type Status = "live" | "draft" | "scheduled" | "cancelled" | "completed";

export class SaleManagerUI {
  idOfSale?: string;
  name: string | null;
  category: string | null;
  end: number | null;
  start: number | null; // TODO change to start
  discount: number | null;
  status?: Status;
  tagsToApplyDiscountToo: string[]; // TODO make sure data from DB is not undefined or null
  tagGroupsToApplyDiscountToo: { tags: string[] }[];
  individualTagsToOveride: OverideObject[];
  tagGroupsToOveride: OverideObject[];
  shopsTags: string[];
  customTypes: string[];
  logs: Log[];
  bannerImageURL?: string;
  bannerImageRef?: string;

  loading: boolean;
  editMode: boolean;
  showProductVariantsOnSale: boolean;
  showLogs: boolean;
  startSaleRightAWay: boolean;

  constructor() {
    this.name = null;
    this.category = null;
    this.end = null;
    this.start = null;
    this.discount = null;
    this.tagsToApplyDiscountToo = [];
    this.tagGroupsToApplyDiscountToo = [];
    this.individualTagsToOveride = [];
    this.tagGroupsToOveride = [];
    this.logs = [];
    this.shopsTags = [
      "Red Beads",
      "Green Beads",
      "Square Beads",
      "Terrible Beads",
      "Beads From God",
      "Beads from hell",
    ]; // TODO remove for prod
    this.customTypes = [
      "gemstones",
      "another example",
      "greystones",
      "applestones",
    ]; // TODO remove for prod
    this.bannerImageURL = undefined;
    this.bannerImageRef = undefined;
    this.loading = true;
    this.editMode = false;
    this.showProductVariantsOnSale = false;
    this.showLogs = false;
    this.startSaleRightAWay = false;

    if (getCurrentEnvironment() === "development") {
      this.name = "test";
      this.category = "greystones";
      this.discount = 10;
      this.start = moment().startOf("day").valueOf();
      this.end = moment().add("days", 4).startOf("day").valueOf();
    }
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async initilize() {
    try {
      this.loading = true;
      let params = new URL(document.location as any).searchParams; // Any cause typescript thinks it's not a string

      // Fetch all existing tags
      const firestore = getFirestore(app);
      const tagsCollection = await getDocs(
        query(collection(firestore, TAGS_COLLECTION_NAME))
      );
      const tags = tagsCollection.docs.map((doc) => doc.data());
      this.shopsTags = removeDuplicates(tags.map((tag) => tag.name));

      // Fetch all existing customTypes
      const customTypesCollection = await getDocs(
        query(collection(firestore, CUSTOM_TYPES_COLLECTION_NAME))
      );
      const customTypes = customTypesCollection.docs.map((doc) => doc.data());
      this.customTypes = customTypes.map((customType) => customType.name);

      let id = params.get("id");
      if (!id) {
        this.editMode = true;
        this.loading = false;
        return; // If Id is null we know to display the create form
      }
      this.idOfSale = id;

      const rawData = await getDoc(
        doc(firestore, SALE_COLLECTION_NAME, this.idOfSale)
      );

      const data = removeUndefinedAndNullFieldFromObject(rawData.data() as any);

      this.name = data.name;
      this.category = data.category;
      this.start = data.start;
      this.end = data.end;
      this.discount = data.discount;
      this.status = data.status;
      this.tagsToApplyDiscountToo = data.tagsToApplyDiscountToo
        ? data.tagsToApplyDiscountToo
        : [];
      this.tagGroupsToApplyDiscountToo = data.tagGroupsToApplyDiscountToo
        ? data.tagGroupsToApplyDiscountToo
        : [];
      this.individualTagsToOveride = data.individualTagsToOveride
        ? data.individualTagsToOveride
        : [];
      this.tagGroupsToOveride = data.tagGroupsToOveride
        ? data.tagGroupsToOveride
        : [];
      this.logs = data.logs ? data.logs : [];
      this.bannerImageURL = data.bannerImageURL;
      this.bannerImageRef = data.bannerImageRef;

      this.loading = false;

      if (this.status === "draft") this.editMode = true;
    } catch (err) {
      handleGeneralError(
        "Sale Manager UI",
        "initilize",
        err,
        "Failed to load sale"
      );
      appLevelUI.pushHistory("/sale-manager-table");
    }
  }

  async saleOrCreate(isDraft: boolean) {
    if (this.idOfSale) {
      this.saveSale(isDraft);
    } else {
      this.createSale(isDraft);
    }
  }

  async saveSale(isDraft: boolean) {
    try {
      if (!this.idOfSale) throw new Error("Something went wrong :(");
      const firestore = getFirestore(app);
      const data = this.getObject();
      await setDoc(
        doc(firestore, SALE_COLLECTION_NAME, this.idOfSale),
        {
          ...data,
          status: isDraft ? "draft" : "scheduled",
          logs: [
            ...(data.logs ? data.logs : []),
            {
              timeStampe: moment().valueOf(),
              type: "user",
              info: "Sale updated",
            },
          ],
        },
        { merge: true }
      );
      toastr.success("Saved Sale Succsfully");
      this.editMode = false;
      this.initilize();
    } catch (err) {
      handleGeneralError("Sale Manager UI", "saveSale", err);
    }
  }

  async deleteSale() {
    try {
      if (!window.confirm("Confirm Delation of sale")) return;
      if (!this.idOfSale) throw new Error("Something went wrong :(");
      const firestore = getFirestore(app);
      await deleteDoc(doc(firestore, SALE_COLLECTION_NAME, this.idOfSale));
      toastr.error("Deleted sale sucsfully. ");
      appLevelUI.pushHistory("/sale-manager-table");
    } catch (err) {
      handleGeneralError("Sale Manager UI", "deleteSale", err);
    }
  }

  async createSale(isDraft: boolean) {
    try {
      if (!this.name) throw new Error("name");
      if (!this.category) throw new Error("Category");
      if (!this.discount) throw new Error("sale discount amount");
      if (!this.end) throw new Error("end time");
      if (this.startSaleRightAWay) {
        // We need the start of the sale to be in the past. So it's logical that the sale is running
        this.start = moment().subtract(100, 'day').valueOf(); // TODO deal with this.end being in the past
      }
      if (!this.start) throw new Error("start time");
      if (this.start >= this.end) {
        toastr.error("Start must be before end.");
        return;
      }
      this.loading = true;
      const firestore = getFirestore(app);
      const formattedSalesData = {
        ...this.getObject(),
        status: isDraft ? "draft" : "scheduled",
        logs: [
          {
            timeStampe: moment().valueOf(),
            type: "user",
            info: "Sale updated",
          },
        ],
      };
      const cleanSalesData = removeUndefinedAndNullFieldFromObject(formattedSalesData);
      const saleId = uuidv4();
      await setDoc(doc(firestore, SALE_COLLECTION_NAME, saleId), {
        ...cleanSalesData,
      });
      if (this.startSaleRightAWay) { 
        fetch(`${salesCoreURL}/trigger-sale`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            saleId: saleId
          }), 
        });
      }
      appLevelUI.pushHistory({
        pathname: "/sale-manager",
        search: `?id=${saleId}`,
      });
      this.loading = false;
    } catch (err) {
      this.loading = false;
      handleGeneralError("Sale Manager UI", "createSale", err);
    }
  }

  async cancelSale() {
    try {
      if (!this.idOfSale) throw new Error("Something went wrong");
      this.loading = true;
      fetch(`${salesCoreURL}/cancel-sale`, {
        body: JSON.stringify({ saleId: this.idOfSale }),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      this.initilize();
      this.loading = false;
      toastr.success(
        "Cancelled sale will start processing, please check back in a few minutes."
      );
      setTimeout(() => {
        window.location.href = "/sale-manager";
      }, 2000);
    } catch (err) {
      handleGeneralError("Sale Manager UI", "Cancel Sale", err);
    }
  }

  async uploadBannerImage(file: File) {
    try {
      if (this.bannerImageRef) {
        await deleteObject(ref(storage, this.bannerImageRef));
      }

      const fileExtension = file.name.split(".").pop();
      const bannerImageRefString = `banner-images/${uuidv4()}.${fileExtension}`;
      const storageRef = ref(storage, bannerImageRefString);
      const metadata = {
        contentType: `image/${fileExtension}`,
      };
      await uploadBytes(storageRef, file, metadata);
      const url = await getDownloadURL(storageRef);

      this.bannerImageURL = url;
      this.bannerImageRef = bannerImageRefString;

      if (!this.idOfSale) return;

      const firestore = getFirestore(app);
      await setDoc(
        doc(firestore, SALE_COLLECTION_NAME, this.idOfSale),
        {
          bannerImageURL: this.bannerImageURL,
          bannerImageRef: bannerImageRefString,
        },
        { merge: true }
      );
    } catch (err) {
      handleGeneralError("Sale Manager UI", "uploadBannerImage", err);
    }
  }

  setStartSaleRightAWay(value: boolean) {
    this.startSaleRightAWay = value;
  }

  reCreateSale() {
    this.editMode = true;
    this.status = "draft";
    this.idOfSale = undefined;
    this.logs = [];
  }

  getObject() {
    return {
      name: this.name,
      category: this.category,
      start: this.start,
      end: this.end,
      discount: this.discount,
      status: this.status,
      tagsToApplyDiscountToo: this.tagsToApplyDiscountToo,
      tagGroupsToApplyDiscountToo: this.tagGroupsToApplyDiscountToo,
      individualTagsToOveride: this.individualTagsToOveride,
      tagGroupsToOveride: this.tagGroupsToOveride,
      logs: this.logs,
      bannerImageURL: this.bannerImageURL,
    };
  }
}

export const saleManagerUI = new SaleManagerUI();
