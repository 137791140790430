import { Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { appLevelUI } from "../../app-level-UI";

export const ResetPasswordPage = () => {
  const [email, setEmail] = useState<string>("");

  return (
    <StyledComponent>
      <Content>
        <Title variant="h1">Reset Password Page</Title>
        <StyledTextField
          fullWidth
          placeholder="email"
          value={email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(event.target.value)
          }
        />
        <StyledButton onClick={() => appLevelUI.resetPassword(email)}>Login</StyledButton>
      </Content>
    </StyledComponent>
  );
};

const StyledComponent = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #eaeaea;
`;

const Content = styled.div`
  width: 30rem;
  margin: auto;
  background-color: white;
  border-radius: 0.5rem;
  padding-bottom: 1rem;
`;

const Title = styled(Typography)`
  && {
    font-size: 1.5rem;
    margin-left: 5%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const StyledTextField = styled(TextField)`
  && {
    margin: 0 auto;
    margin-bottom: 2rem;
    width: 90%;
    display: block;
  }
`;

const StyledButton = styled(Button)`
  && {
    margin-left: 5%;
    display: block;
  }
`;