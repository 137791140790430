import { Checkbox, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { ButterflyLabel, SectionContainer } from "../../reusable-things";
import { saleManagerUI } from "../../sale-manager-ui";
import moment from "moment";

const viewsToUse = ["year", "day"] as any;

export const TimeRangeInput = observer(() => {
  const { start, end, editMode, startSaleRightAWay, idOfSale } = saleManagerUI;

  return (
    <StyledComponent>
      <SaleStartDateContainer>
        {!idOfSale && (
          <>
            <ButterflyLabel>Start sale immediately</ButterflyLabel>
            <Checkbox
              defaultChecked={startSaleRightAWay}
              value={startSaleRightAWay}
              onChange={() =>
                saleManagerUI.setStartSaleRightAWay(!startSaleRightAWay)
              }
            />
          </>
        )}
        <div>
          <ButterflyLabel>Sale start date*</ButterflyLabel>
          <DateTimePicker
            views={viewsToUse}
            disabled={!editMode || startSaleRightAWay}
            value={start}
            onChange={(newValue: any) =>
              (saleManagerUI.start = moment(newValue).startOf("day").valueOf())
            }
            renderInput={(params: any) => <TextField fullWidth {...params} />}
          />
        </div>
      </SaleStartDateContainer>

      <InputContainer>
        <ButterflyLabel>Sale end date*</ButterflyLabel>
        <DateTimePicker
          views={viewsToUse}
          disabled={!editMode}
          value={end}
          onChange={(newValue: any) =>
            (saleManagerUI.end = moment(newValue).startOf("day").valueOf())
          }
          renderInput={(params: any) => <TextField fullWidth {...params} />}
        />
      </InputContainer>
    </StyledComponent>
  );
});

const SaleStartDateContainer = styled.div`
  width: 45%;
`;

const StyledComponent = styled.div`
  ${SectionContainer}
`;

const InputContainer = styled.div`
  width: 45%;
`;
