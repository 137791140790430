import { Card, Modal, Typography } from "@mui/material";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PRODUCT_VARIANTS_COLLECTION_NAME } from "../../../App";
import { app } from "../../../firebaseSetup";
import { saleManagerUI } from "../../sale-manager-ui";
import { turnFirestoreCollectionIntoArray } from "../../universal-utility-functions";
import toastr from "toastr";

export const ItemsOnSaleModal = observer(() => {
  const { idOfSale, showProductVariantsOnSale, discount } = saleManagerUI;

  const [productVariantsOnSale, setProductVariantsOnSale] = useState(
    [] as any[]
  );

  useEffect(() => {
    loadOnSaleProductVariants();
  }, [idOfSale]);

  const loadOnSaleProductVariants = async () => {
    try {
      const firestore = getFirestore(app);
      const variants = turnFirestoreCollectionIntoArray(
        await getDocs(
          query(
            collection(firestore, PRODUCT_VARIANTS_COLLECTION_NAME),
            where("sale", "==", idOfSale || null)
          )
        ),
        {}
      );

      setProductVariantsOnSale(variants);
    } catch (err) {
      toastr.error("Failed to load on sale product variants");
    }
  };

  const closeModal = () => {
    saleManagerUI.showProductVariantsOnSale = false;
  };

  return (
    <StyledComponent onClose={closeModal} open={showProductVariantsOnSale}>
      <Content>
        <TitleAndClose>
          <Title>Products Variants On Sale: {discount}%</Title>
        </TitleAndClose>
        <ProductVariantsContainer>
          {productVariantsOnSale.map((productVariant: any) => {
            return (
              <ProductVariant>
                <Typography variant="h5" component="div">
                  {productVariant.displayName}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  For Developer: {productVariant.uid}
                </Typography>
                <Typography>
                  ${productVariant.compareAtPrice} -{">"} $
                  {productVariant.price}
                </Typography>
              </ProductVariant>
            );
          })}
        </ProductVariantsContainer>
      </Content>
    </StyledComponent>
  );
});

const StyledComponent = styled(Modal)``;

const Content = styled.div`
  background-color: #e7ebf0;
  width: 60%;
  margin: auto;
  height: 80vh;
  margin-top: 10vh;
  border-radius: 10px;
  padding: 1rem;
`;

const Title = styled(Typography)`
  && {
    font-size: 1.25rem;
    margin: auto 0;
    font-weight: bolder;
    margin-right: auto;
  }
`;

const ProductVariantsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  height: 90%;
  overflow-y: scroll;
`;

const ProductVariant = styled(Card)`
  && {
    width: 43%;
    margin-bottom: 1rem;
    padding: 0.5rem;
  }
`;

const TitleAndClose = styled.div``;
