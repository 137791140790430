import { makeAutoObservable } from "mobx";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { createBrowserHistory } from "history";
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import toastr from "toastr";

export class AppLevelUI {
  loggedIn: boolean;
  history: any;
  userToken?: string;

  constructor() {
    this.loggedIn = false;
    this.userToken = undefined;
    this.history = createBrowserHistory({forceRefresh: true}); // force refresh may become a problem I have not tested it fully
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async initialize() {
    const auth = getAuth();

    await auth.onAuthStateChanged(async (currentUser) => {
      if (!currentUser) {
        const pathName = this.history.location.pathname;
        if (pathName !== "/login" && pathName !== "/reset-password") {
          this.pushHistory("/login");
        } // If no user is logged in they must be moved to the login page
      } else {
        const rawIdToken = await auth.currentUser?.getIdTokenResult(true);
        // TODO this does not work on emulator
        this.userToken = rawIdToken?.token;
      }
    });
  }

  async login(email: string, password: string) {
    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      
      this.pushHistory("/home");
    } catch(err) {
      toastr.error("Failed to login. Please contact admin.")
    }
  }

  async resetPassword(email: string) {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      toastr.success("Email sent, if you have not received an email in 5 minutes please try again and verify email.");
    } catch(err) {
      toastr.error("Failed to reset password. Please contact admin.")
    }
  }

  pushHistory(newRoute: string | { pathname: string, search: string }) {
    if (this.history.location.pathname !== newRoute) {
      this.history.push(newRoute);
    }
  }
}

export const appLevelUI = new AppLevelUI();
