import { DocumentData, QuerySnapshot } from "firebase/firestore";

export const turnFirestoreCollectionIntoArray = (firestoreCollection: any, defaults?: object) => {
  const tempArray = [] as any[];
  const defaultToUse = defaults ? defaults : {};
  firestoreCollection.forEach((ele: any) => {
    tempArray.push({
      ref: ele.ref,
      uid: ele.id,
      ...defaultToUse,
      ...ele.data(),
    });
  });
  return tempArray;
};

export const removeUndefinedAndNullFieldFromObject = (inputObject: any) => {
  const workableObject = { ...inputObject };
  Object.keys(workableObject).forEach(
    (key) => (workableObject[key] === undefined || workableObject[key] === null) && delete workableObject[key]
  );
  return { ...workableObject };
};


export const extractNumeralIdFromShopifyId = (shopifyId = "") => {
  const regexMatchResult = shopifyId.match(/\d*$/);
  if (regexMatchResult) {
    return regexMatchResult[0];
  } else {
    throw new Error(`Could not extract numeral id from shopify id: ${shopifyId}`);
  }
};