import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { visuallyHidden } from "@mui/utils";
import styled from "styled-components";
import { cleanerPageUI } from "../../cleaner-page-ui";
import { observer } from "mobx-react-lite";
import { Checkbox, CircularProgress } from "@mui/material";
import { TagsToAddInput } from "./TagsToAddInput";
import moment from "moment";
import {
  getComparator,
  Order,
  stableSort,
} from "../../universal-utility-functions/src/graphFunctions";
import { TagsDisplay } from "./TagsDisplay";
import { InventoryQtyCell } from "./InventoryQtyDisplay";

interface Data {
  name: string;
  lastPurchased: string;
  priceRange: string;
  bottomOfPriceRange: string;
  inventory: string;
  status: string;
  tags: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "priceRange",
    numeric: false,
    disablePadding: false,
    label: "Price Range",
  },
  {
    id: "lastPurchased",
    numeric: false,
    disablePadding: false,
    label: "Last Sold",
  },
  {
    id: "inventory",
    numeric: true,
    disablePadding: false,
    label: "Inventory Qty",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "tags",
    numeric: false,
    disablePadding: false,
    label: "Tags",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCellStyled padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCellStyled>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            align={"center"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabelStyled
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabelStyled>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  selected: string[];
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected, selected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          SKUs Table: {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          SKUs Table
        </Typography>
      )}
      {numSelected > 0 && <TagsToAddInput selected={selected} />}
    </Toolbar>
  );
};

export const SkuCleanerTable = observer(() => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("name");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { productsToDisplay: data, loadingTable } = cleanerPageUI;

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n: any) => n.uid);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, uid: string) => {
    const selectedIndex = selected.indexOf(uid);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, uid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty data.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const getWhatToDisplayOnTable = () => {
    if (loadingTable)
      return (
        <TableBody>
          <TableRow>
            <TableCellStyled colSpan={50}>
              <TableBodyMessageDisplay variant="h4">
                <LoadingContainer>
                  <StyledCircularProgress />
                </LoadingContainer>
              </TableBodyMessageDisplay>
            </TableCellStyled>
          </TableRow>
        </TableBody>
      );
    if (data.length === 0)
      return (
        <TableBody>
          <TableRow>
            <TableCellStyled colSpan={50}>
              <TableBodyMessageDisplay variant="h4">
                No result available
              </TableBodyMessageDisplay>
            </TableCellStyled>
          </TableRow>
        </TableBody>
      );
    return (
      <TableBody>
        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
        data.slice().sort(getComparator(order, orderBy)) */}
        {stableSort(data, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row: any, index) => {
            const isItemSelected = isSelected(row.uid);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow
                hover
                onClick={(event) => handleClick(event, row.uid)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.uid}
                selected={isItemSelected}
              >
                <TableCellStyled padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </TableCellStyled>
                <TableCellStyled component="th" id={labelId} scope="row">
                  {row.name}
                </TableCellStyled>
                <TableCellStyled component="th" id={labelId} scope="row">
                  ${row.minVariantPrice} ~ ${row.maxVariantPrice}
                </TableCellStyled>
                <TableCellStyled component="th" id={labelId} scope="row">
                  {moment(row.lastPurchased).format("DD-MMM-YYYY")}
                </TableCellStyled>
                <TableCellStyled component="th" id={labelId} scope="row">
                  <InventoryQtyCell
                    inventoryQuantity={row.inventoryQuantity}
                    inventoryUpdatedAt={row.inventoryQuantityUpdatedAt}
                  />
                </TableCellStyled>
                <TableCellStyled component="th" id={labelId} scope="row">
                  {row.status || "N/A"}
                </TableCellStyled>
                <TableCellStyled component="th" id={labelId} scope="row">
                  <TagsDisplay tags={row.tags} />
                </TableCellStyled>
              </TableRow>
            );
          })}
        {emptyRows > 0 && (
          <TableRow
            style={{
              height: (dense ? 33 : 53) * emptyRows,
            }}
          >
            <TableCellStyled colSpan={6} />
          </TableRow>
        )}
      </TableBody>
    );
  };

  return (
    <Container sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          selected={[...selected]}
          numSelected={selected.length}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            {getWhatToDisplayOnTable()}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Container>
  );
});

const Container = styled(Box)`
  margin-top: 2rem;
`;

export const TableBodyMessageDisplay = styled(Typography)`
  && {
    text-align: center;
    padding: 2rem 0;
    width: 100%;
    display: block;
  }
`;

export const LoadingContainer = styled.div`
  margin: auto;
  width: fit-content;
  padding: 2rem 0;
`;

export const StyledCircularProgress = styled(CircularProgress)`
  && {
    margin: auto;
  }
`;

export const TableSortLabelStyled = styled(TableSortLabel)`
  && {
    font-size: 0.8rem;
    width: 7em;
  }
`;

export const TableCellStyled = styled(TableCell)`
  && {
    width: 7em;
    text-align: center;
  }
`;
