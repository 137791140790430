import { Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { BackButton } from "../../reusable-things/src/BackButton";
import { saleManagerTableUI } from "../../sale-manager-ui/src/saleManagerTableUI";
import { SaleManagerTable } from "./SaleManagerTable";

export const SaleManagerTablePage = () => {
  return (
    <StyledComponent>
      <BackButtonAndTitle>
        <BackButton to="/home" />
        <Title variant="h4">Sale Manager</Title>
      </BackButtonAndTitle>
      <SaleManagerTable />
      <ButtonContainer>
        <Link to="/sale-manager" style={{ textDecoration: "none" }}>
          <Button variant={"contained"}>Create Sale</Button>
        </Link>
        <StyledButton
          onClick={() => saleManagerTableUI.triggerSales()}
          variant={"contained"}
        >
          Activate Sales
        </StyledButton>
      </ButtonContainer>
      <SubTitle variant="h4">When and what it runs:</SubTitle>
      <Typography>
        Ever day at midnight it goes through all sales checking if they should
        be run. A sale should be run if it's starting time is in the present or
        past and it's status is scheduled. It then goes through all sales again
        and looks if any should end. It does this by checking if the end date is
        in the present or past and the status is live.
      </Typography>
      <br />
      <SubTitle variant="h4">
        What should I do if I made a mistake and want to edit a live sale:
      </SubTitle>
      <Typography>
        Cancel the sale then re-create it (make sure to save it as scheduled).
        Then on the table page there is a activate sales button if you press
        that the sale should start within 2-5 minutes (most likely sooner, but
        after 5 minutes please alert us). You can tell the sale started if the
        status is live.
      </Typography>
    </StyledComponent>
  );
};

const StyledComponent = styled.div`
  width: 75rem;
  padding-top: 5rem;
  margin: auto;
`;

const Title = styled(Typography)`
  && {
    font-size: 1.25rem;
    margin: auto 0;
    font-weight: bolder;
  }
`;

const SubTitle = styled(Typography)`
  && {
    font-weight: normal;
    font-size: 1.5rem;
  }
`;

const BackButtonAndTitle = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  && {
    margin-left: 2rem;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 2rem;
`;
