import {
  Autocomplete,
  Chip,
  TextField,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { ButterflyInput, SectionContainer } from "../../reusable-things";
import { ButterflyLabel } from "../../reusable-things/src/ButterflyInputLabel";
import { convertStringToNumberSafe } from "../../reusable-things/src/cleaningAndVerifyingDataFunctions";
import { saleManagerUI } from "../../sale-manager-ui";
import { filterOptionsLimit } from "../../universal-utility-functions";
import { SaleManagerPageButton } from "./SaleManagerPageButton";

export const OverideTagsInput = observer(() => {
  const { shopsTags, individualTagsToOveride, editMode, tagGroupsToOveride } =
    saleManagerUI;
  return (
    <StyledSectionContainer>
      <ButterflyLabel>
        Override discount for individual product tags
      </ButterflyLabel>
      {individualTagsToOveride.map((tagGroup, index) => {
        return (
          <TagsAndTypeOfOverideContainer>
            <TempDeleteIcon
              onClick={() => {
                saleManagerUI.individualTagsToOveride.splice(index, 1);
              }}
            >
              X
            </TempDeleteIcon>
            <StyledAutocomplete
              disabled={!editMode}
              multiple
              filterOptions={filterOptionsLimit}
              limitTags={2}
              options={shopsTags}
              value={tagGroup.tags}
              onChange={(_, newValue) =>
                (saleManagerUI.individualTagsToOveride[index].tags = (newValue as any))
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="e.g: Red Beads" />
              )}
              renderTags={(value: any, getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              sx={{ width: "500px" }}
            />
            <OveridesContainer>
              <FormControlLabel
                control={
                  <Radio
                    disabled={!editMode}
                    checked={tagGroup.noSale}
                    onChange={() => {
                      tagGroup.noSale = true;
                      tagGroup.amount = undefined;
                    }}
                  />
                }
                label="No sale"
              />
              <FormControlLabel
                control={
                  <Radio
                    disabled={!editMode}
                    checked={!tagGroup.noSale}
                    onChange={() => {
                      tagGroup.noSale = false;
                    }}
                  />
                }
                label="Other discount amount (%)"
              />
              <ButterflyInput
                disabled={tagGroup.noSale || !editMode}
                placeholder="e.g: 50 = 50%"
                value={tagGroup.amount}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  (tagGroup.amount = convertStringToNumberSafe(
                    event.target.value
                  ))
                }
              />
            </OveridesContainer>
          </TagsAndTypeOfOverideContainer>
        );
      })}

      <StyledSaleManagerPageButton
        onClick={() =>
          (saleManagerUI.individualTagsToOveride = [
            ...saleManagerUI.individualTagsToOveride,
            {
              noSale: true,
              tags: [],
            },
          ])
        }
        variant={"outlined"}
        disabled={!editMode}
      >
        + add another tag combination
      </StyledSaleManagerPageButton>
      <br />
      <ButterflyLabel>
        Override discount for product tags combination
      </ButterflyLabel>
      {tagGroupsToOveride.map((tagGroup, index) => {
        return (
          <TagsAndTypeOfOverideContainer>
            <TempDeleteIcon
              onClick={() => {
                saleManagerUI.tagGroupsToOveride.splice(index, 1);
              }}
            >
              X
            </TempDeleteIcon>
            <Autocomplete
              disabled={!editMode}
              multiple
              limitTags={2}
              options={shopsTags}
              value={tagGroup.tags}
              onChange={(_, newValue) =>
                (saleManagerUI.tagGroupsToOveride[index].tags = newValue)
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="e.g: Red Beads" />
              )}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <>
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                    {index !== value.length - 1 && "&"}
                  </>
                ))
              }
              sx={{ width: "500px" }}
            />
            <OveridesContainer>
              <FormControlLabel
                control={
                  <Radio
                    disabled={!editMode}
                    checked={tagGroup.noSale}
                    onChange={() => {
                      tagGroup.noSale = true;
                      tagGroup.amount = undefined;
                    }}
                  />
                }
                label="No sale"
              />
              <FormControlLabel
                control={
                  <Radio
                    disabled={!editMode}
                    checked={!tagGroup.noSale}
                    onChange={() => {
                      tagGroup.noSale = false;
                    }}
                  />
                }
                label="Other discount amount (%)"
              />
              <ButterflyInput
                disabled={tagGroup.noSale || !editMode}
                placeholder="e.g: 50 = 50%"
                value={tagGroup.amount}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  (tagGroup.amount = convertStringToNumberSafe(
                    event.target.value
                  ))
                }
              />
            </OveridesContainer>
          </TagsAndTypeOfOverideContainer>
        );
      })}
      <SaleManagerPageButton
        onClick={() =>
          (saleManagerUI.tagGroupsToOveride = [
            ...saleManagerUI.tagGroupsToOveride,
            {
              noSale: true,
              tags: [],
            },
          ])
        }
        variant={"outlined"}
        disabled={!editMode}
      >
        + add another tag combination
      </SaleManagerPageButton>
    </StyledSectionContainer>
  );
});

const TempDeleteIcon = styled.p`
  color: red;
  cursor: pointer;
`;

const StyledSectionContainer = styled.div`
  ${SectionContainer};
  display: block;
`;

const TagsAndTypeOfOverideContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const OveridesContainer = styled.div`
  display: flex;
`;

const StyledSaleManagerPageButton = styled(SaleManagerPageButton)`
  && {
    margin-bottom: 2rem;
  }
`;

const StyledAutocomplete = styled(Autocomplete)``;