// I am disabling it as I am using a variable with proper string literals, it just can't tell
/* eslint-disable valid-typeof */
export const convertStringToNumber = (string: string) => {
  if (typeof string === "number") return string;
  if (!string || typeof string !== "string") return NaN;
  return parseFloat(string.replace(/,/g, "."));
};

export const convertStringToNumberSafe = (string: string) => {
  if (typeof string === "number") return string;
  if (!string || typeof string !== "string") return 0;
  return parseFloat(string.replace(/,/g, ".")) ? parseFloat(string.replace(/,/g, ".")) : 0;
};