import { Chip } from "@mui/material";
import React from "react";
import styled from "styled-components";

type TagsDisplayProps = {
  tags: string[];
};

export const TagsDisplay = ({ tags }: TagsDisplayProps) => {
  return (
    <StyledComponent>
      {tags.map((tag: string) => (
        <TagContainer label={tag} />
      ))}
    </StyledComponent>
  );
};

type StyledComponentProps = {};

const StyledComponent = styled.div<StyledComponentProps>`
  display: flex;
  flex-wrap: wrap;
  overflow-y: hidden;
  height: 2rem;
`;

const TagContainer = styled(Chip)`
  && {
    margin-bottom: 0.25rem;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    background-color: white;
    margin-right: 0.25rem;

    & > span {
      padding: 4px;
    }
  }
`;
