import { TextField, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

type ButterflyLabelProps = {
    children: React.ReactNode;
    className?: string;
}

export const ButterflyLabel = ({children, className}: ButterflyLabelProps) => {
  return (
    <Label className={className}>
      {children}
    </Label>
  )
}

const Label = styled(Typography)``;