import { Add, Block, Delete, Edit } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import styled from "styled-components";
import { BackButton } from "../../reusable-things/src/BackButton";
import { saleManagerUI } from "../../sale-manager-ui";

export const SaleManagerHeader = observer(() => {
  const { status, editMode } = saleManagerUI;

  const titleString = useMemo(() => {
    switch (status) {
      case "draft":
        return "Draft";
      case "scheduled":
        return "Scheduled Sale";
      case "live":
        return <span color="#2E7D32">Sale is Live!</span>;
      case "cancelled":
        return <span color="#D32F2F">Sale was cancelled</span>;
      case "completed":
        return "Sale Completed";
      default:
        return "Draft";
    }
  }, [status]);

  return (
    <StyledComponent>
      <BackButton to="/sale-manager-table" />
      {status === "live" && (
        <Button
          onClick={() => (saleManagerUI.showProductVariantsOnSale = true)}
        >
          View Items On Sale
        </Button>
      )}
      <Title variant="h4">{titleString}</Title>
      {(status === "draft" || editMode) && (
        <>
          <ActionButton
            onClick={() => saleManagerUI.deleteSale()}
            variant={"outlined"}
            color="error"
          >
            <Delete /> Delete
          </ActionButton>
        </>
      )}
      {status === "scheduled" && !editMode && (
        <>
          <ActionButton
            onClick={() => (saleManagerUI.editMode = true)}
            variant={"outlined"}
            color="success"
          >
            <Edit /> Edit
          </ActionButton>
          <ActionButton
            onClick={saleManagerUI.deleteSale}
            variant={"outlined"}
            color="error"
          >
            <Delete /> Delete
          </ActionButton>
        </>
      )}
      {status === "live" && (
        <ActionButton
          onClick={() => saleManagerUI.cancelSale()}
          variant={"outlined"}
          color="error"
        >
          <Block /> Cancel Sale
        </ActionButton>
      )}
      {(status === "completed" || status === "cancelled") && (
        <ActionButton
          onClick={() => saleManagerUI.reCreateSale()}
          variant={"outlined"}
          color="success"
        >
          <Add /> Re-create sale
        </ActionButton>
      )}
    </StyledComponent>
  );
});

const StyledComponent = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

const Title = styled(Typography)`
  && {
    font-size: 1.25rem;
    margin: auto 0;
    font-weight: bolder;
    margin-right: auto;
  }
`;

const ActionButton = styled(Button)`
  && {
    height: 3rem;
    margin: 0 1rem;
  }
`;
